import React from "react"

const TurnkeyFraming = () => [
  <>
    <h3>Turnkey Framing</h3>
    <p>
      Time is money. Valuable time can be saved by using Capital Structures
      Turnkey Frame Construction. Since the 1970's there has been a steady
      decline in the number of skilled laborers available in the construction
      industry. The ratio has reversed from ten skilled laborers for every one
      unskilled laborer to the opposite, one skilled laborer for every 10
      unskilled.
    </p>
    <p>
      It is getting more and more difficult to secure skilled labor on the
      construction site. Capital Structures Turnkey Frame Construction cuts
      down on jobsite errors and omissions by working with you from the
      beginning.
    </p>
    <p>
      From the start, we use your plans to design and engineer the structural
      building components, such as floor trusses, roof trusses and wall panels.
      Then, we send a trained installation crew to make sure the structure is
      built according to the plans.
    </p>
    <p>
      Turnkey Frame Construction is the cost effective way to meet today's real
      work construction demands.
    </p>
  </>,
]

export default TurnkeyFraming
