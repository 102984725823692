import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InstallationServices from "../components/installationServices"
import TurnkeyFraming from "../components/turnkeyFraming"

const RoofFloorTrusses = () => {
  const data = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "Trusses_blue_sky.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Roof &amp; Floor Trusses" />
      <Helmet
        bodyAttributes={{
          class: "page-roof-floor-trusses",
        }}
      />
      <Row>
        <Col sm={12}>
          <h1>Roof & Floor Trusses</h1>
        </Col>
        <Col md={9}>
          <img
            className="img-fluid float-right ml-3 mb-3"
            src={data.photo1.childImageSharp.fluid.src}
            alt="Trusses blue sky"
          />
          <p>
            Our customers benefit from over 45 years of combined design
            experience in the manufactured component industry. We take great
            care to understand the needs of each customer individually. Whether
            purchasing roof trusses, floor trusses, engineered lumber products,
            wall panels, or any combination thereof, our customers can rest
            assured that they are receiving the most efficient and cost
            effective component system, and that it is specifically designed to
            suit their construction project.
          </p>
          <Button href="/contact" variant="primary">
            Get Started Today
          </Button>
          <h3>Benefits of Roof Trusses</h3>
          <ul>
            <li>
              Complex roof and ceiling profiles are easy to design with today’s
              software.
            </li>
            <li>
              Hip and valley roof systems are much easier to build using
              trusses than with conventional framing.
            </li>
            <li>
              Trusses can be used with a variety of on-center spacing, to
              optimize strength and lumber resources.
            </li>
            <li>
              Long, clear spans are easy to create, reducing or eliminating the
              need for interior bearing walls, beams, and columns.
            </li>
            <li>
              Structures are dried in more quickly, saving time and avoiding
              weather-related delays.
            </li>
            <li>
              Your imagination is the only limit when you design with trusses.
            </li>
          </ul>
          <h3>Benefits of Floor Trusses</h3>
          <ul>
            <li>
              Floor truss installations create high quality, squeak-free
              construction with the added benefits of reduced framing time,
              waste, pilferage, and callbacks.
            </li>
            <li>
              Design versatility and open web spaces are tremendous advantages
              to builders of today’s complex and sophisticated homes.
            </li>
            <li>
              The fact that floor trusses are built with 2x4s or 2x3s oriented
              ‘flatwise’ means a wide, stable bearing surface that is easier to
              work on and around.
            </li>
            <li>
              The wide nailing surface provides for easy gluing and quick,
              accurate attachment of sheathing, reducing squeaks and improving
              floor performance for the life of the structure.
            </li>
            <li>
              Spacing floor trusses at 16” or 24” o.c. maximizes structural
              efficiency and speed of installation.
            </li>
            <li>
              Stiffness and strength can be designed into the floor truss,
              creating a more solid floor.
            </li>
            <li>
              Special bearing, cantilever and balcony details are easily built
              in.
            </li>
            <li>
              Shrinkage, warping or twisting are minimized, reducing the
              potential for callbacks to the job site.
            </li>
          </ul>
        </Col>
        <Col md={3}>
          <TurnkeyFraming />
          <InstallationServices />
        </Col>
      </Row>
    </Layout>
  )
}

export default RoofFloorTrusses
