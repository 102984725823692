import React from "react"
import Button from "react-bootstrap/Button"

const InstallationServices = () => [
  <>
    <h3>Installation Services</h3>
    <p>
      Don’t have the time, resources or expertise for an installation
      project? Let Capital Structures help.
    </p>
    <p>
      Our team of construction professionals can install all of the manufactured
      components we make for you. Best of all, we make sure it gets done right,
      efficiently, and according  to your plans.
    </p>
    <Button href="/contact" variant="primary">
      Contact Us
    </Button>
  </>,
]

export default InstallationServices
